import React, {useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';

import {createInstance, OptimizelyProvider} from '@optimizely/react-sdk';

import { BaseContext } from '../../context/base.context';

import { ScrollToTop } from '../../helpers/scroll-to-top';
import { AppThemeProvider } from '../../context/theme-provider';

import { useCountryCode } from '../../hooks/useCountryCode.hook';

import { RouterConfig } from '../../routes';

import { PageConfigurationService } from '../../services/page-configuration.service';

import { Content } from '../content';

import { OPTIMIZELY_ATTRIBUTES } from '../../constants';

export const pageConfiguration = new PageConfigurationService();

function Index() {
    const { countryCode } = useCountryCode();

    const optimizely = createInstance({
        sdkKey: process.env.REACT_APP_OPTIMIZELY_API_KEY
    });

    return (
        <>
            <OptimizelyProvider
                optimizely={optimizely}
                user={{ id: pageConfiguration.getUserId(), attributes: OPTIMIZELY_ATTRIBUTES }}>
                <BaseContext.Provider value={{
                    countryCode,
                    optimizely,
                    pageConfiguration
                }}>
                    <AppThemeProvider>
                        <BrowserRouter>
                            <ScrollToTop />
                            <Content>
                                <RouterConfig />
                            </Content>
                        </BrowserRouter>
                    </AppThemeProvider>
                </BaseContext.Provider>
            </OptimizelyProvider>
        </>
    );
}

export default Index;
