import { API } from './api';

class GeoCoding extends API {

    getUserData() {
        return fetch(`${process.env.REACT_APP_GEOCODING_API_URL}`, { method: 'GET' })
            .then((response) => response.json())
            .catch((e) => console.log(e));
    }
}

export const GeoCodingProvider = new GeoCoding();
