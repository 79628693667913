import { useEffect } from "react";

import { GeoCodingProvider } from '../api/geo-coding.api';

import { useLocalStorage } from './useLocalStorage';

const CUBA_KEY = 'CU';
const US_KEY = 'US';

export const useCountryCode = () => {
    const { pageValue: countryCode, setPageValue: setCountryCode } = useLocalStorage({ key: 'countryCode', defaultValue: '' });

    useEffect(() => {
        if (countryCode) {
            return;
        }

        GeoCodingProvider.getUserData().then((res: any) => {
            const countryCode = res?.countryCode;
            if (countryCode) {
                const code = countryCode === CUBA_KEY ? US_KEY : countryCode;
                setCountryCode(code);
            } else {
                // @description: Set default country code to US
                setCountryCode(US_KEY);
            }
        });
    }, []);

    return {
        countryCode
    }
};
